body {
  font-family: "Arial", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #f4f4f4;
}

.game-container {
  text-align: center;
}

.message {
  font-size: 18px;
  margin-bottom: 10px;
}

.btn-container {
  margin-bottom: 20px;
}

button {
  font-size: 16px;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

button:hover {
  background-color: #2980b9;
}

.board {
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.cell {
  width: 100px;
  height: 100px;
  border: 2px solid #3498db;
  font-size: 24px;
  cursor: pointer;
  background-color: #fff;
  text-align: center;
  position: relative;
  transition: background-color 0.3s; /* Added smooth transition */
}

.cell:hover {
  background-color: #ecf0f1;
}

.cell.clicked {
  background-color: #ecf0f1; /* Set to the hover color */
}

.cell.clicked::before {
  content: attr(data-symbol);
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
